<template>
  <v-expansion-panels>
    <v-expansion-panel
        v-for="(i, key) in messages"
        :key="key"
        class="expansion-custom"
    >
      <v-expansion-panel-header>
        <v-icon class="icon-custom">mdi-email</v-icon>
        <div>
          <label class="ml-10 pl-1" >{{i.title}}</label>
          <v-subheader class="subheader-custom ml-7">{{ i.created_at | toCurrency}}</v-subheader>
        </div>
        <div>
          <v-btn
              v-if="i.document"
              dark
              icon
              color="primary"
              class="ma-0 text--darken-1 btn-document"
              elevation="0"
              v-on:click="openDocument(i.document)">
            <v-icon>mdi-file-document</v-icon>
          </v-btn>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-divider></v-divider>
        <p  class="d-block mt-2">{{i.message}}</p>

        <v-expansion-panels v-if="i.listRead && i.listRead.length > 0">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Le&iacute;dos
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider></v-divider>
              <v-list subheader>
                <v-subheader>Inversores que han le&iacute;do el mensaje</v-subheader>
                <v-list-item
                    v-for="(j, jkey) in i.listRead"
                    :key="`sub${jkey}`"
                >
                  <v-list-item-avatar>
                    <v-img
                        :alt="`${j.user.name} ${j.user.last_name}`"
                        :src="(j.user.pictures !== null && j.user.pictures && j.user.pictures.length > 0) ? j.user.pictures[0].url : require('@/assets/images/no-image.jpg')"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{j.user.name}} {{j.user.last_name}}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon color="deep-purple accent-4">
                      mdi-account-check
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels v-if="i.listNotRead && i.listNotRead.length > 0">
          <v-expansion-panel>
            <v-expansion-panel-header>
              No le&iacute;dos
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider></v-divider>
              <v-list subheader>
                <v-subheader>Inversores que no han le&iacute;do el mensaje</v-subheader>
                <v-list-item
                    v-for="(j, jkey) in i.listNotRead"
                    :key="`sub${jkey}`"
                >
                  <v-list-item-avatar>
                    <v-img
                        :alt="`${j.user.name} ${j.user.last_name}`"
                        :src="(j.user.pictures !== null && j.user.pictures && j.user.pictures.length > 0) ? j.user.pictures[0].url : require('@/assets/images/no-image.jpg')"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{j.user.name}} {{j.user.last_name}}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon color="grey">
                      mdi-account-clock
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "ItemNotificationComponent",
  props: ["messages"],

  filters:{
    toCurrency(value){
      return (new Date(value)).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' });
    },
  },
  methods: {
    openDocument(document) {
      window.open(document, "_blank");
    },
  },
}
</script>

<style scoped>
  .icon-custom{
    display: inline-block !important;
    position: absolute;
    font-size: 2.5rem;
    color: #ded06f;
  }
  .subheader-custom{
    height: auto !important;
    padding-top: 0.1rem !important;
  }
  .expansion-custom{
    margin-top: 1px !important;
  }
  .btn-document{
    margin-left: auto !important;
    margin-right: 0.3rem !important;
    display: flex;
  }
</style>