<template>
  <NotificationComponent/>
</template>

<script>
import NotificationComponent from "@/components/admin/NotificationComponent.vue";

export default {
  name: "NotificationsModule",
  components: {NotificationComponent}
}
</script>

<style scoped>

</style>