<template>
  <v-container fill-height fluid text-xs-center>
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-card-title>
        <v-col sm="12" lg="4" class="pt-0 pb-0">
          <span class="mr-2 mt-auto mb-auto">Notificaciones</span>
          <v-dialog
              transition="dialog-bottom-transition"
              max-width="375"
              persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  elevation="2"
              >
                <v-icon>mdi-bell-plus-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >
                  <h3>Crear notificaci&oacute;n</h3>
                </v-toolbar>
                <v-card-text>
                  <v-form v-model="valid">
                    <v-text-field
                        v-model="createNotificationModel.title"
                        label="Título"
                        :rules="titleMessageFileRules"
                        :disabled="loadingSave"
                    ></v-text-field>
                    <v-textarea
                        v-model="createNotificationModel.message"
                        label="Mensaje"
                        :rules="titleMessageFileRules"
                        :disabled="loadingSave"
                    ></v-textarea>
                    <v-select
                        v-model="createNotificationModel.investors"
                        :items="investors"
                        label="Inversores"
                        item-text="name"
                        item-value="id"
                        :rules="investorsRules"
                        multiple
                        :disabled="loadingSave"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                        >
                          (+ otros {{ createNotificationModel.investors.length - 1 }})
                        </span>
                      </template>
                    </v-select>
                    <v-file-input
                        show-size
                        label="Fichero"
                        v-model="createNotificationModel.file"
                        truncate-length="32"
                        :disabled="loadingSave"
                    ></v-file-input>
                  </v-form>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                      @click="()=>{
                        createNotificationModel={
                          title: '',
                          message: '',
                          investors: [],
                          file: null,
                        };
                        valid= false;
                        dialog.value = false;
                      }"
                      :disabled="loadingSave"
                  >Cancelar</v-btn>
                  <v-btn
                      depressed
                      color="primary darken-1 caption"
                      :disabled="!valid"
                      :loading="loadingSave"
                      @click="sendNotificationAction"
                  >
                    <v-icon dark>mdi-send-variant</v-icon>
                    Enviar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
        <v-col sm="12" lg="8" class="pt-0 pb-0">
          <v-text-field
              v-model="searchItem"
              outlined
              label="Buscar"
              placeholder="Buscar"
              class="mr-0 ml-auto"
              @keyup="search">
          </v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div v-if="loading">
          <v-skeleton-loader class="mb-1" type="image" height="3.3rem" width="100%"></v-skeleton-loader>
          <v-skeleton-loader class="mb-1" type="image" height="3.3rem" width="100%"></v-skeleton-loader>
          <v-skeleton-loader class="mb-1" type="image" height="3.3rem" width="100%"></v-skeleton-loader>
          <v-skeleton-loader class="mb-1" type="image" height="3.3rem" width="100%"></v-skeleton-loader>
          <v-skeleton-loader class="mb-1" type="image" height="3.3rem" width="100%"></v-skeleton-loader>
          <v-skeleton-loader type="image" height="3.3rem" width="100%"></v-skeleton-loader>
        </div>
        <ItemNotificationComponent v-else :messages="message"/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import userService from "@/providers/UsersService";
import investorService from "@/providers/InvestorService";
import ItemNotificationComponent from "./ItemNotificationComponent";
import Pusher from "pusher-js";

export default {
  name: "NotificationComponent",
  components: {
    ItemNotificationComponent
  },
  data: () => ({
    message:[],
    obj: {
      id:0,
      type:"",
      full: true
    },
    valid: false,
    titleMessageFileRules: [
      v => !!v || 'El campo es requerido',
    ],
    investorsRules: [
        v=> v.length > 0 || 'El campo es requerido'
    ],
    investors: [],
    createNotificationModel:{
      title: "",
      message: "",
      investors: [],
      file: null,
    },
    aux: [],
    searchItem: "",
    loading: true,
    loadingSave: false,
    pusher:  new Pusher('13e4db1337e9189ec1aa', {
      cluster: 'us2'
    }),
    channel: null,
  }),
  methods:{
    search(){
      this.message = this.aux.filter((e)=>e.message.toLowerCase().includes(this.searchItem.toLowerCase()));
    },
    sendNotificationAction(){
      this.loadingSave = true;
      userService.sendNotification({
        title: this.createNotificationModel.title,
        message: this.createNotificationModel.message,
        investors: this.createNotificationModel.investors,
        file: this.createNotificationModel.file,
      }).then(resp=>{
        if(resp.success){
          this.loadData();
          this.createNotificationModel={
            title: '',
            message: '',
            investors: [],
            file: null,
          };
          this.valid= false;
          this.dialog.value = false;
        }
      }).catch(err=>console.log(err)).finally(()=>{
        this.loadingSave = false;
      })
    },
    loadData(){
      this.loading = true;
      userService.getNotificationAdmin().then((response) => {
        if(response.success){
          this.message = response.value.map((e)=>{
            return {
              id: e.id,
              title: e.title,
              message: e.message,
              document: e.document,
              created_at: e.created_at,
              listRead: e.investors.filter(e=>e.pivot.read == true),
              listNotRead: e.investors.filter(e=>e.pivot.read == false),
            }
          });
          this.aux = [...this.message];
        }
      }).catch(err=>console.log(err)).finally(()=>{
        this.loading = false;
      });
    }
  },
  mounted() {
    this.loadData();
    investorService.getAllInvestors().then(resp => {
      this.investors = !resp.value?[]:resp.value.map(e=>{
        return {
          id: e.id,
          name: `${e.user.name} ${e.user.last_name}`,
        }
      });
    });
    this.channel = this.pusher.subscribe("read-notification");
    this.channel.bind("read-notification-for-admin", (data)=> {
      this.message.forEach((item)=>{
        if(item.id==data.notification_id){
          const investor = item.listNotRead.find((e=>e.id==data.investor_id));
          item.listNotRead = item.listNotRead.filter((e=>e.id!=data.investor_id));
          item.listRead.push(investor);
        }
      });
    });
  }
}
</script>

<style scoped>

</style>